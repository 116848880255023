import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import GettingStartedContent from '../../components/HelpCenter/GettingStarted/GettingStarted'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  HC_GETTING_STARTED,
  HELP_CENTRE,
} from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateDownloadInstructionVideoSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Help Centre',
    url: HELP_CENTRE,
  },
  {
    title: 'Getting Started',
    url: HC_GETTING_STARTED,
  },
]

const GettingStarted: React.FC = () => {
  const [gettingStartedFaqsSt, setGettingStartedFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ gettingStartedFaqs }) =>
      setGettingStartedFaqsSt(gettingStartedFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Getting Started: Get Help on App Installation and SignUp | Mega"
        description="Visit our getting started help centre for answers to queries on how to install Mega app, signup, play the first game and more. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(gettingStartedFaqsSt)}
        videoSchema={[generateDownloadInstructionVideoSchema()]}
      />
      <GettingStartedContent faqs={gettingStartedFaqsSt} />
    </Layout>
  )
}

export default GettingStarted
